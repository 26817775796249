import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    // Home
    { path: '', loadChildren: () => import('./components/pages/home/home.module').then(m => m.HomeModule), data: { breadcrumb: "Home" } },
    { path: 'home-v2', loadChildren: () => import('./components/pages/home-two/home-two.module').then(m => m.HomeTwoModule), data: { breadcrumb: "Home" } },
    { path: 'home-v3', loadChildren: () => import('./components/pages/home-three/home-three.module').then(m => m.HomeThreeModule), data: { breadcrumb: "Home" } },

    // About Us
    { path: 'sobre', loadChildren: () => import('./components/pages/about/about.module').then(m => m.AboutModule), data: { breadcrumb: "A Empresa" } },
    { path: 'carbono-zero', loadChildren: () => import('./components/pages/faqs/faqs.module').then(m => m.FaqsModule), data: { breadcrumb: "Carbono Zero" } },

    // Services
    { path: 'servicos', loadChildren: () => import('./components/pages/services/services.module').then(m => m.ServicesModule), data: { breadcrumb: "Serviços" } },
    { path: 'servico-detalhes/:id', loadChildren: () => import('./components/pages/service-details/service-details.module').then(m => m.ServiceDetailsModule), data: { breadcrumb: "Mais Informações" } },

    // Team
    { path: 'solucoes', loadChildren: () => import('./components/pages/team/team.module').then(m => m.TeamModule), data: { breadcrumb: "Soluções" } },
    { path: 'solucoes/:id', loadChildren: () => import('./components/pages/team-details/team-details.module').then(m => m.TeamDetailsModule), data: { breadcrumb: "Soluções" } },

    // Company
    { path: 'company', loadChildren: () => import('./components/pages/company/company.module').then(m => m.CompanyModule), data: { breadcrumb: "Company History" } },

    // Portfolio
    { path: 'sites', loadChildren: () => import('./components/pages/portfolio-grid/portfolio-grid.module').then(m => m.PortfolioGridModule), data: { breadcrumb: "Sites" } },
    { path: 'portfolio-masonry', loadChildren: () => import('./components/pages/portfolio-masonry/portfolio-masonry.module').then(m => m.PortfolioMasonryModule), data: { breadcrumb: "Portfolio Masonry" } },
    { path: 'portfolio-details/:id', loadChildren: () => import('./components/pages/portfolio-details/portfolio-details.module').then(m => m.PortfolioDetailsModule), data: { breadcrumb: "Portfolio Details" } },

    // Pages
    { path: 'faqs', loadChildren: () => import('./components/pages/faqs/faqs.module').then(m => m.FaqsModule), data: { breadcrumb: "FAQ's" } },
    { path: 'testimonials', loadChildren: () => import('./components/pages/testimonials/testimonials.module').then(m => m.TestimonialsModule), data: { breadcrumb: "Testimonials" } },
    { path: 'pricing', loadChildren: () => import('./components/pages/pricing/pricing.module').then(m => m.PricingModule), data: { breadcrumb: "Pricing" } },
    { path: 'career', loadChildren: () => import('./components/pages/career/career.module').then(m => m.CareerModule), data: { breadcrumb: "Career" } },
    { path: 'error-404', loadChildren: () => import('./components/pages/error-page/error-page.module').then(m => m.ErrorPageModule), data: { breadcrumb: "Erro 404" } },

    // Blog
    { path: 'blog/cat/:catId', loadChildren: () => import('./components/pages/blog/blog.module').then(m => m.BlogModule), data: { breadcrumb: "Por Categoria" } },
    { path: 'blog/tag/:tagId', loadChildren: () => import('./components/pages/blog/blog.module').then(m => m.BlogModule), data: { breadcrumb: "Por Tag" } },
    { path: 'blog/author/:authorId', loadChildren: () => import('./components/pages/blog/blog.module').then(m => m.BlogModule), data: { breadcrumb: "Por Autor" } },
    { path: 'blog/search/:query', loadChildren: () => import('./components/pages/blog/blog.module').then(m => m.BlogModule), data: { breadcrumb: "Busca" } },
    { path: 'blog', loadChildren: () => import('./components/pages/blog/blog.module').then(m => m.BlogModule), data: { breadcrumb: "Novidades" } },
    { path: 'blog-standard', loadChildren: () => import('./components/pages/blog-standard/blog-standard.module').then(m => m.BlogStandardModule), data: { breadcrumb: "Novidades" } },
    { path: 'postagem/:id', loadChildren: () => import('./components/pages/blog-details/blog-details.module').then(m => m.BlogDetailsModule), data: { breadcrumb: "Detalhes" } },

    // Contact
    { path: 'contato', loadChildren: () => import('./components/pages/contact/contact.module').then(m => m.ContactModule), data: { breadcrumb: "Contato" } },

    { path: '**', loadChildren: () => import('./components/pages/error-page/error-page.module').then(m => m.ErrorPageModule), data: { breadcrumb: "Erro 404" } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
